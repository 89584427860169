@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: theme('colors.main');
  overflow-x: hidden;
}

.herowave {
  background-image: url(./assets/herowave.svg);
  aspect-ratio: 960/540;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  bottom: 0;
  position: absolute;
  z-index: -1;
}

.aboutmewave {
  background-image: url(./assets/aboutmewave.svg);
  aspect-ratio: 960/540;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  bottom: 0;
  position: relative;
  z-index: -1;
}

.projectwave {
  background-image: url(./assets/projectwave.svg);
  aspect-ratio: 1450/800;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  bottom: 0;
}

.contactwave {
  background-image: url(./assets/contactwave.svg);
  aspect-ratio: 1450/800;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  bottom: 0;
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-in-btm {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-in-right {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-in-left.visible {
  opacity: 1;
  transform: translateX(0px);
}

.fade-in-btm.visible {
  opacity: 1;
  transform: translateY(0px);
}

.fade-in-right.visible {
  opacity: 1;
  transform: translateX(0px);
}

.fade-in.visible {
  opacity: 1;
}